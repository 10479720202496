import React from "react"

// const EventPage = () => (
//   <html lang="en">
//     <head>
//       <title>Huckfest 2020</title>
//       <meta
//         http-equiv="refresh"
//         content="0; url = https://trailguide.net/#1E?map=trailguide"
//       />
//     </head>
//     <body class="text-xl">
//       <p>Redirecting to event...</p>
//     </body>
//   </html>
// )

const EventPage = () => (
  <html lang="en">
    <head>
      <title>No current events</title>
    </head>
    <body class="text-xl">
      <p>No current events...</p>
    </body>
  </html>
)

export default EventPage
